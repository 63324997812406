import { GLRichTextEditor } from '@app/components';
import { LazyPortal } from '@app/components/admin/lazy-portal';
import PreventClickingMask from "@app/components/prevent-clicking-mask";
import { PathConfig } from '@app/config/pathconfig';
import { GSAdminLocale } from '@app/locales/localeid';
import { IRegionService } from '@app/service/admin/regions';
import { ISchoolClassService } from '@app/service/class/index';
import { INotificationService, NotificationModel, NotificationRoleNames, NotificationRoles, NotificationRoleTargetModel, NotificationTargetTypeNames, NotificationTargetTypes } from '@app/service/notifications/index';
import { ISchoolService } from '@app/service/schools/index';
import { IUserService } from '@app/service/users/index';
import { setBreadcrumb } from '@app/states/admin/resource';
import { DateHelper, distinct, EmailParameter, fmtMsg, isPastDate, lazyInject, lengthValider, NotificationChannel, stripHtmlTags, TYPES, urlValider } from '@app/util/index';
import { Button, Checkbox, DatePicker, Icon, Input, Modal, Popover, Radio, Select, Spin, Table } from "antd-min";
import * as DOMPurify from 'dompurify';
import { alignPop, FormHelper, GLForm, GLFormComponentProps, GLGlobal, GLLocale, MessageHelper, NotificationType, RegionInfoModel, RoleName, ServerExceptionCode } from "gl-commonui";
import debounce from 'lodash/debounce';
import moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import EmailEditor from 'react-email-editor';
import { RouteComponentProps } from 'react-router-dom';
import "./notificationfill.less";
import { ServiceExceptionCode as ExceptionCode } from "@app/util/enum";

type UserInfo = { name: string, email: string }
interface TargetSelectModel {
    region: { selected: boolean, loaded: boolean, selectedId: string, resources: { id: string, name: string }[] }
    school: { selected: boolean, loaded: boolean, selectedId: string, resources: { id: string, name: string }[] }
    campus: { selected: boolean, loaded: boolean, selectedId: string, resources: { id: string, name: string }[] }
    schoolClass: { selected: boolean, loaded: boolean, selectedId: string, resources: { id: string, name: string }[] }
}
interface NotificationFillProps {
    isEdit?: boolean
}
interface NotificationFillStates {
    modal: {
        roleTarget: { visible: boolean, disabled: boolean, loading: boolean },
        user: { visible: boolean },
        userNotified: { visible: boolean },
        advancedEditor: { visible: boolean }
    }
    loading: boolean
    model: NotificationModel
    selectRole: NotificationRoles
    selectTargetType: NotificationTargetTypes
    showTargetSelect: TargetSelectModel
    notifyChanged: NotificationToTargets
    formatRoleTargets: NotificationRoleTargetModel[]
    notifiedUserCount: number
    notifyUsers: UserInfo[]
    notifiedUsers: UserInfo[]
    notifiedFilterUsers: UserInfo[]
    checkNotifyList: boolean
    searchUser: any
    probablyUsers: any[]
    userFetching: boolean
    titlelinkVisible: boolean
    descriptionlinkVisible: boolean
    useAdvancedEditor: boolean
    disableBtnAdvanceEditor: boolean
    targetCountHash: string
    disableBtnSubmit: boolean
    selectedRegion: string
}

@GLForm.create()
export class NotificationFillPage extends Component<NotificationFillProps & RouteComponentProps<any> & GLFormComponentProps, NotificationFillStates> {
    @lazyInject(TYPES.INotificationService)
    notificationService: INotificationService
    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService
    @lazyInject(TYPES.IUserService)
    userService: IUserService

    originRoleTargets: NotificationRoleTargetModel[] = []
    lastFetchId = 0;
    lastFetchUsers = null;
    continue = false;
    enableDebounce = false;
    userSelects = []
    userSelectSearch
    usersFilter
    notificationChannel = [NotificationChannel.BulletinBoard];
    richTextNotificationDescription = "";
    richTextNotificationDescriptionError = "";

    advancedNotificationDescription = "";
    advancedNotificationDesign = null;
    emailEditorRef: any = React.createRef();
    shadowHost: any = React.createRef();
    accessibleRegions: RegionInfoModel[];

    constructor(props, context) {
        super(props, context);
        this.state = {
            modal: {
                roleTarget: { visible: false, disabled: false, loading: false },
                user: { visible: false },
                userNotified: { visible: false },
                advancedEditor: { visible: false },
            },
            model: {},
            loading: false,
            selectRole: NotificationRoles.All,
            selectTargetType: NotificationTargetTypes.All,
            showTargetSelect: this.getInitTargetSelect(),
            notifyChanged: NotificationToTargets.Roles,
            formatRoleTargets: [],
            notifiedUserCount: 0,
            notifyUsers: [],
            notifiedUsers: [],
            notifiedFilterUsers: [],
            checkNotifyList: false,
            searchUser: [],
            probablyUsers: [],
            userFetching: false,
            titlelinkVisible: false,
            descriptionlinkVisible: false,
            useAdvancedEditor: false,
            disableBtnAdvanceEditor: true,
            targetCountHash: '',
            disableBtnSubmit: false,
            selectedRegion: GLGlobal.loginInfo().profile.regionInfo && GLGlobal.loginInfo().profile.regionInfo.length > 0 ? GLGlobal.loginInfo().profile.regionInfo[0].id : null
        }
        this.userSelectSearch = debounce((searchUser) => {
            if (searchUser == undefined || searchUser.trim().length < 3) return;
            this.onUserSelectSearch(searchUser.trim())
        }, 800);
        this.usersFilter = debounce((key) => {
            this.setNotifiedFilterUsers(key)
        }, 800)
        this.accessibleRegions = GLGlobal.loginInfo().profile.regionInfo || [];
        this.onRegionChange = this.onRegionChange.bind(this);
    }
    componentDidMount() {
        if (this.props.isEdit) {
            this.notificationService.getDetail({ id: this.props.match.params.notificationId }).then(({ notification, notifiedCount }: any) => {
                setBreadcrumb({ notification: { id: this.props.match.params.notificationId, name: notification.title } })
                this.notificationChannel = this.getNotificationChannel4Display(notification.channel);
                if (notification.notificationType == NotificationToTargets.Roles) {
                    this.setState({
                        model: notification,
                        formatRoleTargets: notification.roleTargets.map(this.formatRoleTarget).map(d => (d.isCalculated = true, d)),
                        notifiedUserCount: notifiedCount,
                        notifyChanged: NotificationToTargets.Roles,
                    }, () => {
                        this.getTargetCount(this.state.formatRoleTargets.map(fr => (fr.isCalculated = true, fr)))
                    });
                } else {
                    this.setState({
                        model: notification,
                        notifyUsers: notification.roleTargets.map(rt => ({
                            ...rt,
                            rowKey: rt.targetId,
                            id: rt.targetId,
                            ...(JSON.parse(rt.targetName))
                        })),
                        notifyChanged: NotificationToTargets.Users,
                    });
                }

                const { isAdvanced, advancedDescription, description } = notification;
                if (isAdvanced) {
                    this.advancedNotificationDescription = description;
                    this.advancedNotificationDesign = JSON.parse(advancedDescription);
                    this.setState({
                        useAdvancedEditor: true
                    })
                }
                else {
                    this.richTextNotificationDescription = description;
                    this.setState({
                        useAdvancedEditor: false
                    })
                }
            }).catch(({ error_code }) => {
                if (error_code == ServerExceptionCode.TargetIsNullException) {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: GSAdminLocale.NotificationDetail }));
                }
            })
        } else {
            const form = this.props.form;
            form.setFieldsValue({
                startDate: moment(new Date(), 'YYYY-MM-DD'),
                endDate: moment(new Date(), 'YYYY-MM-DD').add(1, "day"),
                notificationType: NotificationToTargets.Roles,
                sticky: false
            });
            this.notificationChannel = [NotificationChannel.BulletinBoard];
        }
    }
    getInitTargetSelect() {
        return {
            region: { selected: false, loaded: false, selectedId: undefined, resources: [] },
            school: { selected: false, loaded: false, selectedId: undefined, resources: [] },
            campus: { selected: false, loaded: false, selectedId: undefined, resources: [] },
            schoolClass: { selected: false, loaded: false, selectedId: undefined, resources: [] },
        } as TargetSelectModel;
    }
    onRegionChange(value) {
        this.setState({ selectedRegion: value });
    }
    getRegionAndTenantInfo() {
        const regionInfo = this.accessibleRegions.find(x => x.id == this.state.selectedRegion);
        let regionId = null;
        let tenantId = null;
        if (regionInfo) {
            regionId = regionInfo.id;
            tenantId = regionInfo.tenantId;
        }

        return { regionId, tenantId };
    }
    exceptTargetSelect(selectdId, includeTargetType) {
        let nextTargetSelect = { ...this.state.showTargetSelect };
        nextTargetSelect[NotificationTargetTypes[includeTargetType].toLowerCase()].selectedId = selectdId;
        const exceptSelected = (excepts) => excepts.forEach(except => {
            var target = nextTargetSelect[except];
            target.loaded = false;
            target.selectedId = undefined;
            target.resources = [];
        });
        switch (includeTargetType) {
            case NotificationTargetTypes.All:
                nextTargetSelect = this.getInitTargetSelect();
            case NotificationTargetTypes.Region:
                exceptSelected(['school', 'campus', 'schoolClass']);
                break;
            case NotificationTargetTypes.School:
                exceptSelected(['campus', 'schoolClass']);
                break;
            case NotificationTargetTypes.Campus:
                exceptSelected(['schoolClass']);
                break;
        }
        this.setState({ showTargetSelect: nextTargetSelect });
    }
    showTargetSelects(targetType, showTargetSelect) {
        showTargetSelect.region.selected = targetType >= NotificationTargetTypes.Region;
        showTargetSelect.school.selected = targetType >= NotificationTargetTypes.School;
        showTargetSelect.campus.selected = targetType >= NotificationTargetTypes.Campus;
        showTargetSelect.schoolClass.selected = targetType >= NotificationTargetTypes.SchoolClass;
    }
    getNotificationChannel() {
        return this.notificationChannel.length == 1 ? this.notificationChannel[0] : NotificationChannel.BulletinBoardAndEmail;
    }
    getNotificationChannel4Display(channel) {
        return channel == 2 ? [NotificationChannel.BulletinBoard, NotificationChannel.Email] : [channel];
    }
    onSubmit(e) {
        e.preventDefault();
        var fields = Object.getOwnPropertyNames(this.props.form.getFieldsValue()).filter(field => field !== 'text' && field !== 'url')
        this.props.form.validateFields(fields, async (err, values) => {
            values.description = this.state.useAdvancedEditor ? this.advancedNotificationDescription : this.richTextNotificationDescription;
            values.isAdvanced = this.state.useAdvancedEditor;
            values.advancedDescription = JSON.stringify(this.advancedNotificationDesign);

            if (!this.validateDescription()) {
                return;
            }

            if (!err && this.checkNotifyList(values) && this.checkNotifyStartDate(values)) {
                values.roleTargets = this.state.formatRoleTargets;
                if (values.notificationType == NotificationToTargets.Users) {
                    values.roleTargets = this.state.notifyUsers;
                }
                values.channel = this.getNotificationChannel();
                const notifyRole = values.notificationType == NotificationToTargets.Roles;
                if (notifyRole) values.targetCountHash = this.state.targetCountHash;
                try {
                    if (this.props.isEdit) {
                        values.id = this.state.model.id;
                        values.createdBy = this.state.model.createdBy;
                        values.creationDate = this.state.model.creationDate;
                        await this.notificationService.update(values, { id: values.id });
                    } else {
                        await this.notificationService.create(values);
                    }

                    const notifyCount = notifyRole ? this.state.notifiedUserCount : this.state.notifyUsers.length;
                    if ((values.channel == NotificationChannel.BulletinBoardAndEmail || values.channel == NotificationChannel.Email)
                        && notifyCount > EmailParameter.EmailsLimited) {
                        MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: GSAdminLocale.NotificationSendEmailToMore3k }));
                    }

                    this.props.history.push({ pathname: PathConfig.NotificationCreated });
                } catch ({ error_code }) {
                    if (error_code == ExceptionCode.EmailsLimitException) {
                        MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: GSAdminLocale.NotificationEmailLimited }, { limitNumberEmails: process.env.limitNumberEmails }));
                        this.setState({ disableBtnSubmit: true })
                    }
                    else if (error_code == ExceptionCode.EmailsTargetLimitException) {
                        MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: GSAdminLocale.NotificationTargetEmailLimited }));
                    }
                }
            }
        });
    }

    checkNotifyList({ notificationType }) {
        var pass = true;
        if (notificationType === NotificationToTargets.Roles && this.state.formatRoleTargets.length == 0) {
            pass = false;
        } else if (notificationType === NotificationToTargets.Users && this.state.notifyUsers.length == 0) {
            pass = false;
        }
        this.setState({ checkNotifyList: !pass });
        return pass;
    }
    checkNotifyListCallBack() {
        this.checkNotifyList({ notificationType: this.props.form.getFieldValue('notificationType') })
    }
    checkNotifyStartDate({ startDate }) {
        const isPast = isPastDate(startDate, 'millisecond');
        const channel = this.getNotificationChannel();
        if (isPast && channel != NotificationChannel.BulletinBoard) {
            MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationMsgSubmitError }));
            return false;
        }
        return true;
    }
    onRolesSelect(selectRole) {
        const modal = { ...this.state.modal };
        modal.roleTarget.disabled = false;
        this.setState({
            selectRole,
            selectTargetType: NotificationTargetTypes.All,
            showTargetSelect: this.getInitTargetSelect(),
            modal
        });
    }
    onTargetTypesSelect(targetType) {
        let showTargetSelect = this.getInitTargetSelect();
        let modal = { ...this.state.modal };
        if (targetType != NotificationTargetTypes.All) {
            modal.roleTarget.disabled = true;

            this.showTargetSelects(targetType, showTargetSelect);

            this.notificationService.getRegions().then((d: { disabled: boolean }[]) => {
                showTargetSelect = { ...this.state.showTargetSelect };
                showTargetSelect.region.loaded = true;
                showTargetSelect.region.resources = d.filter(d => !d.disabled) as any;
                this.setState({ showTargetSelect });
            });

        } else {
            modal.roleTarget.disabled = false;
            showTargetSelect = this.getInitTargetSelect();
        }

        this.setState({ selectTargetType: targetType, showTargetSelect, modal });
    }
    targetCompletedSelect(targetType) {
        let lastTargetType = NotificationTargetTypes.All;
        if (this.state.showTargetSelect.schoolClass.selected) {
            lastTargetType = NotificationTargetTypes.SchoolClass;
        } else if (this.state.showTargetSelect.campus.selected) {
            lastTargetType = NotificationTargetTypes.Campus;
        } else if (this.state.showTargetSelect.school.selected) {
            lastTargetType = NotificationTargetTypes.School;
        } else if (this.state.showTargetSelect.region.selected) {
            lastTargetType = NotificationTargetTypes.Region;
        }
        const modal = { ...this.state.modal };
        modal.roleTarget.disabled = !(lastTargetType == targetType);
        this.setState({ modal });
        return lastTargetType == targetType;
    }
    onTargetSelect(selectdId, targetType: NotificationTargetTypes) {
        switch (targetType) {
            case NotificationTargetTypes.Region:
                this.exceptTargetSelect(selectdId, NotificationTargetTypes.Region);
                if (this.targetCompletedSelect(NotificationTargetTypes.Region)) return;
                this.schoolService.getAccessibleWithoutDisabled(selectdId, false).then((d: { id, name }[]) => {
                    const showTargetSelect = { ...this.state.showTargetSelect };
                    showTargetSelect.region.selectedId = selectdId;
                    showTargetSelect.school.loaded = true;
                    showTargetSelect.school.resources = distinct(d, d => d.id);
                    this.setState({ showTargetSelect });
                });
                break;
            case NotificationTargetTypes.School:
                this.exceptTargetSelect(selectdId, NotificationTargetTypes.School);
                if (this.targetCompletedSelect(NotificationTargetTypes.School)) return;
                this.schoolService.getAccessibleCampuses(selectdId, false).then((d: { id, name }[]) => {
                    const showTargetSelect = { ...this.state.showTargetSelect };
                    showTargetSelect.school.selectedId = selectdId;
                    showTargetSelect.campus.loaded = true;
                    showTargetSelect.campus.resources = distinct(d, d => d.id);
                    this.setState({ showTargetSelect });
                });
                break;
            case NotificationTargetTypes.Campus:
                const schoolId = this.state.showTargetSelect.school.selectedId;
                this.exceptTargetSelect(selectdId, NotificationTargetTypes.Campus);
                if (this.targetCompletedSelect(NotificationTargetTypes.Campus)) return;
                this.schoolClassService.getAccessibleClasses(
                    schoolId,
                    selectdId,
                    false
                ).then(d => {
                    const showTargetSelect = { ...this.state.showTargetSelect };
                    showTargetSelect.campus.selectedId = selectdId;
                    showTargetSelect.schoolClass.loaded = true;
                    showTargetSelect.schoolClass.resources = distinct(d.data, d => d.id);
                    this.setState({ showTargetSelect });
                });
                break;
            case NotificationTargetTypes.SchoolClass:
                this.state.showTargetSelect.schoolClass.selectedId = selectdId;
                this.targetCompletedSelect(NotificationTargetTypes.SchoolClass);
                this.setState({ showTargetSelect: { ...this.state.showTargetSelect } });
                break;
            default:
                break;
        }
    }
    getTargetId(targetType) {
        switch (Number(targetType)) {
            case NotificationTargetTypes.Region:
                return this.state.showTargetSelect.region.selectedId;
            case NotificationTargetTypes.School:
                return this.state.showTargetSelect.school.selectedId;
            case NotificationTargetTypes.Campus:
                return this.state.showTargetSelect.campus.selectedId;
            case NotificationTargetTypes.SchoolClass:
                return this.state.showTargetSelect.schoolClass.selectedId;
            default:
                return undefined;
        }
    }
    addOriginRoleTargets() {
        const roleTargt = {
            role: this.state.selectRole,
            targetType: this.state.selectTargetType,
            targetId: this.getTargetId(this.state.selectTargetType)
        } as NotificationRoleTargetModel
        this.originRoleTargets.push(roleTargt);
        return this.originRoleTargets;
    }
    getOption(value, text) {
        text = fmtMsg(text);
        return <Select.Option key={value} value={value.toString()} title={text}>{text}</Select.Option>;
    }
    renderRoleOptions() {
        var options = [];
        if (userHasRole(RoleName.systemAdmin) || userHasRole(RoleName.globalHead)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.GlobalHead, GSAdminLocale.NotificationCreateGlobalHead),
                this.getOption(NotificationRoles.TrainingAdmin, GSAdminLocale.NotificationCreateTrainingAdministrator),
                this.getOption(NotificationRoles.RegionAdmin, GSAdminLocale.NotificationCreateRegionAdministrator),
                this.getOption(NotificationRoles.TrainingManager, GSAdminLocale.NotificationCreateTrainingManager),
                this.getOption(NotificationRoles.Trainer, GSAdminLocale.NotificationCreateTrainer),
                this.getOption(NotificationRoles.AccountManager, GSAdminLocale.NotificationCreateAccountManager),
                this.getOption(NotificationRoles.SchoolAdmin, GSAdminLocale.NotificationCreateSchoolAdministrator),
                this.getOption(NotificationRoles.CampusAdmin, GSAdminLocale.NotificationCreateCampusAdministrator),
                this.getOption(NotificationRoles.Teacher, GSAdminLocale.NotificationCreateTeacher),
                this.getOption(NotificationRoles.Parent, GSAdminLocale.NotificationCreateParent),
            ]);
        }
        if (userHasRole(RoleName.regionAdmin)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.RegionAdmin, GSAdminLocale.NotificationCreateRegionAdministrator),
                this.getOption(NotificationRoles.TrainingManager, GSAdminLocale.NotificationCreateTrainingManager),
                this.getOption(NotificationRoles.Trainer, GSAdminLocale.NotificationCreateTrainer),
                this.getOption(NotificationRoles.AccountManager, GSAdminLocale.NotificationCreateAccountManager),
                this.getOption(NotificationRoles.SchoolAdmin, GSAdminLocale.NotificationCreateSchoolAdministrator),
                this.getOption(NotificationRoles.CampusAdmin, GSAdminLocale.NotificationCreateCampusAdministrator),
                this.getOption(NotificationRoles.Teacher, GSAdminLocale.NotificationCreateTeacher),
                this.getOption(NotificationRoles.Parent, GSAdminLocale.NotificationCreateParent),
            ]);
        }
        if (userHasRole(RoleName.trainingAdmin)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.TrainingAdmin, GSAdminLocale.NotificationCreateTrainingAdministrator),
                this.getOption(NotificationRoles.TrainingManager, GSAdminLocale.NotificationCreateTrainingManager),
                this.getOption(NotificationRoles.Trainer, GSAdminLocale.NotificationCreateTrainer),
            ]);
        }
        if (userHasRole(RoleName.trainer)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.Trainer, GSAdminLocale.NotificationCreateTrainer),
                this.getOption(NotificationRoles.SchoolAdmin, GSAdminLocale.NotificationCreateSchoolAdministrator),
                this.getOption(NotificationRoles.CampusAdmin, GSAdminLocale.NotificationCreateCampusAdministrator),
                this.getOption(NotificationRoles.Teacher, GSAdminLocale.NotificationCreateTeacher),
                this.getOption(NotificationRoles.Parent, GSAdminLocale.NotificationCreateParent),
            ]);
        }
        if (userHasRole(RoleName.trainingManager)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.TrainingManager, GSAdminLocale.NotificationCreateTrainingManager),
                this.getOption(NotificationRoles.Trainer, GSAdminLocale.NotificationCreateTrainer),
            ]);
        }

        if (userHasRole(RoleName.accountManager)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.Trainer, GSAdminLocale.NotificationCreateTrainer),
                this.getOption(NotificationRoles.AccountManager, GSAdminLocale.NotificationCreateAccountManager),
                this.getOption(NotificationRoles.SchoolAdmin, GSAdminLocale.NotificationCreateSchoolAdministrator),
                this.getOption(NotificationRoles.CampusAdmin, GSAdminLocale.NotificationCreateCampusAdministrator),
                this.getOption(NotificationRoles.Teacher, GSAdminLocale.NotificationCreateTeacher),
                this.getOption(NotificationRoles.Parent, GSAdminLocale.NotificationCreateParent),
            ]);
        }

        if (userHasRole(RoleName.schoolAdmin)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.SchoolAdmin, GSAdminLocale.NotificationCreateSchoolAdministrator),
                this.getOption(NotificationRoles.CampusAdmin, GSAdminLocale.NotificationCreateCampusAdministrator),
                this.getOption(NotificationRoles.Teacher, GSAdminLocale.NotificationCreateTeacher),
                this.getOption(NotificationRoles.Parent, GSAdminLocale.NotificationCreateParent),
            ]);
        }

        if (userHasRole(RoleName.campusAdmin)) {
            options = options.concat([
                this.getOption(NotificationRoles.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationRoles.CampusAdmin, GSAdminLocale.NotificationCreateCampusAdministrator),
                this.getOption(NotificationRoles.Teacher, GSAdminLocale.NotificationCreateTeacher),
                this.getOption(NotificationRoles.Parent, GSAdminLocale.NotificationCreateParent),
            ]);
        }
        return distinct(options, op => op.key);
    }
    removeRegionByTrainer(options: any[]) {
        return hasOneRole(RoleName.trainer) ? options.filter(op => op.key != NotificationTargetTypes.Region) : options;
    }
    removeRegionByAccountManager(options: any[]) {
        return hasOneRole(RoleName.accountManager) ? options.filter(op => op.key != NotificationTargetTypes.Region) : options;
    }
    removeRegion(options: any[]) {
        return isUserHasRegionTargeType() ? options : options.filter(op => op.key != NotificationTargetTypes.Region);
    }
    removeSchool(options: any[]) {
        return isUserHasSchoolTargeType() ? options : options.filter(op => op.key != NotificationTargetTypes.School);
    }
    renderTargetTypeOptions() {
        switch (Number(this.state.selectRole)) {
            case NotificationRoles.All:
                return this.getTargetTypeOptionsByUserRole();
            case NotificationRoles.RegionAdmin:
                return [
                    this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion)
                ]
            case NotificationRoles.TrainingAdmin:
            case NotificationRoles.GlobalHead:
                return [
                    this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll)
                ]
            case NotificationRoles.AccountManager:
            case NotificationRoles.Trainer:
                return [
                    this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                    this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool)
                ]
            case NotificationRoles.SchoolAdmin:
                return [
                    this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                    this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                ]
            case NotificationRoles.CampusAdmin:
                return [
                    this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                    this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                    this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                ]
            case NotificationRoles.TrainingManager:
                return [
                    this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                ]
            case NotificationRoles.Teacher:
            case NotificationRoles.Parent:
                return [
                    this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                    this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                    this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                    this.getOption(NotificationTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass),
                ]
        }
        return [
            this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll)
        ];
    }
    getTargetTypeOptionsByUserRole() {
        var options = [];
        if (userHasRole(RoleName.systemAdmin) || userHasRole(RoleName.globalHead)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(NotificationTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass),
            ]);
        }
        if (userHasRole(RoleName.regionAdmin)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(NotificationTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass),
            ]);
        }
        if (userHasRole(RoleName.trainingAdmin)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
            ]);
        }

        if (userHasRole(RoleName.trainer)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(NotificationTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass),
            ]);
        }

        if (userHasRole(RoleName.trainingManager)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
            ]);
        }

        if (userHasRole(RoleName.accountManager)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(NotificationTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass),
            ]);
        }

        if (userHasRole(RoleName.schoolAdmin)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(NotificationTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass),
            ]);
        }

        if (userHasRole(RoleName.campusAdmin)) {
            options = options.concat([
                this.getOption(NotificationTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(NotificationTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(NotificationTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass),
            ]);
        }
        return distinct(options, op => op.key);
    }
    setTargetOptionWidth(className) {
        const { region, school, campus, schoolClass } = this.state.showTargetSelect;
        if (schoolClass.selected) return className;
        if (campus.selected) return `${className} three-options`;
        if (school.selected) return `${className} two-options`;
        if (region.selected) return `${className} one-options`;
        return className;
    }
    renderTargetOptions(show, value, placeholder, resources, loaded, type) {
        return show ? <Select dropdownClassName={`drop-down ${loaded ? "" : "loading"}`} className={this.setTargetOptionWidth("target-select")} placeholder={placeholder} value={value} onChange={value => this.onTargetSelect(value, type)} notFoundContent={loaded ? undefined : <Spin size="small" />}>{resources && resources.map(r => this.getOption(r.id, r.name))}</Select> : null;
    }
    onModalButtonClick() {
        this.setState({
            modal: {
                ...this.state.modal,
                roleTarget: {
                    visible: false,
                    disabled: false,
                    loading: false
                }
            },
            showTargetSelect: this.getInitTargetSelect(),
            selectRole: NotificationRoles.All,
            selectTargetType: NotificationTargetTypes.All
        })
    }
    formatRoleTarget(rt: NotificationRoleTargetModel, rowKey) {
        return {
            ...rt,
            rowKey,
            roleName: NotificationRoleNames[NotificationRoles[rt.role]],
            targetTypeName: NotificationTargetTypeNames[NotificationTargetTypes[rt.targetType]]
        }
    }
    getTargetCount(roleTargets, callback?) {
        this.notificationService.getTargetCount(roleTargets).then((d: { roleTargets, notifiedUserCount, targetCountHash, users }) => {
            this.originRoleTargets = [];
            this.setState({
                formatRoleTargets: d.roleTargets.map(this.formatRoleTarget),
                notifiedUserCount: d.notifiedUserCount,
                targetCountHash: d.targetCountHash
            }, this.checkNotifyListCallBack.bind(this));
            callback && callback();

            let promises = [];
            const users = d.users as any[];
            while (users.length > 0) {
                promises.push(this.userService.getItemsBy({ ids: users.splice(0, 150) }))
            }
            Promise.all(promises).then((d: any[]) => {
                this.setState({ notifiedUsers: d.reduce((pre, cur) => pre.concat(cur.data), []) }, () => {
                    this.setNotifiedFilterUsers();
                })
            })

        })
    }
    getTargetCountFromModal() {
        this.setState({ modal: { ...this.state.modal, roleTarget: { ...this.state.modal.roleTarget, loading: true } } })
        var roleTargets = distinct(this.state.formatRoleTargets.map(frt => (frt.isCalculated = true, frt)).concat(this.addOriginRoleTargets()), rt => `${rt.role}${rt.targetType}${rt.targetId || GUID_EMPTY}`);
        this.getTargetCount(roleTargets, this.onModalButtonClick.bind(this));
    }
    renderSelectRoleTargetModalFooter() {
        return (
            <div>
                <Button type="primary" disabled={this.state.modal.roleTarget.disabled} loading={this.state.modal.roleTarget.loading}
                    onClick={() => {
                        this.continue = false;
                        if (this.state.disableBtnSubmit) this.setState({ disableBtnSubmit: false })
                        this.getTargetCountFromModal();
                    }}>{fmtMsg({ id: GSAdminLocale.NotificationCreateSubmit })}</Button>
                <Button onClick={() => {
                    if (this.continue) {
                        this.continue = false;
                        this.getTargetCountFromModal();
                    }
                    else {
                        this.onModalButtonClick();
                    }
                }}>{fmtMsg(GLLocale.Cancel)}</Button>
            </div>
        )
    }
    renderSelectRoleTargetModal() {
        const { region, school, campus, schoolClass } = this.state.showTargetSelect;
        return (
            <Modal
                className="role-target-modal"
                title={fmtMsg({ id: GSAdminLocale.NotificationCreateNotifyRoleTarget })}
                visible={this.state.modal.roleTarget.visible}
                maskClosable={false}
                destroyOnClose
                onCancel={this.onModalButtonClick.bind(this)}
                footer={this.renderSelectRoleTargetModalFooter()}
            >
                <div>
                    <p><label htmlFor="Role" className="ant-form-item-required">{fmtMsg({ id: GSAdminLocale.NotificationCreateRole })}</label></p>
                    <Select className="role-target-select" value={this.state.selectRole.toString()} onChange={this.onRolesSelect.bind(this)}>
                        {this.renderRoleOptions()}
                    </Select>
                </div>
                <div>
                    <p><label htmlFor="Target Type" className="ant-form-item-required">{fmtMsg({ id: GSAdminLocale.NotificationCreateTargetType })}</label></p>
                    <Select className="role-target-select" value={this.state.selectTargetType.toString()} onChange={this.onTargetTypesSelect.bind(this)}>
                        {this.removeSchool(this.removeRegion(this.renderTargetTypeOptions()))}
                    </Select>
                </div>
                <div>
                    {region.selected && <p><label htmlFor="Target" className="ant-form-item-required">{fmtMsg({ id: GSAdminLocale.NotificationCreateTarget })}</label></p>}
                    {this.renderTargetOptions(region.selected, region.selectedId, 'Select Region', region.resources, region.loaded, NotificationTargetTypes.Region)}
                    {this.renderTargetOptions(school.selected, school.selectedId, 'Select School', school.resources, school.loaded, NotificationTargetTypes.School)}
                    {this.renderTargetOptions(campus.selected, campus.selectedId, 'Select Campus', campus.resources, campus.loaded, NotificationTargetTypes.Campus)}
                    {this.renderTargetOptions(schoolClass.selected, schoolClass.selectedId, 'Select School Class', schoolClass.resources, schoolClass.loaded, NotificationTargetTypes.SchoolClass)}
                </div>
            </Modal>
        )
    }
    onUserSelectChange(searchUser) {
        this.setState({
            searchUser,
            probablyUsers: [],
            userFetching: false,
        });
    }
    onUserSelectSearch(searchUser) {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        const regionInfo = this.getRegionAndTenantInfo();
        this.setState({ probablyUsers: [], userFetching: true });
        this.userService.getItemsBy({ userName: searchUser, email: searchUser, regionId: regionInfo.regionId, tenantId: regionInfo.tenantId,
            excludeUserCount: true, excludePendingUsers: true, excludeUserRole: true, excludeNotifications: true }).then(d => {
            if (fetchId !== this.lastFetchId) return;
            const probablyUsers = d.data.map(user => ({
                id: user.id,
                name: user.name,
                email: user.email,
            })).filter(user => !this.state.searchUser.some(su => su.key === user.id));
            this.setState({
                probablyUsers,
                userFetching: false
            });
        })
    }
    renderSelectUserModal() {
        const hide = () => this.setState({ modal: { ...this.state.modal, user: { ...this.state.modal.user, visible: false } }, searchUser: [], probablyUsers: [], userFetching: false, selectedRegion: this.accessibleRegions.length > 0 ? this.accessibleRegions[0].id : null });
        const { userFetching, probablyUsers, searchUser } = this.state;
        const fmtEmail = ({ name, email }) => `${name} ${email ? `(${email})` : ''}`
        return (
            <Modal
                title={fmtMsg({ id: GSAdminLocale.NotificationCreateSearchName })}
                visible={this.state.modal.user.visible}
                okText="Submit"
                className="notified-user-modal"
                maskClosable={false}
                destroyOnClose
                confirmLoading={searchUser.length == 0}
                onOk={() => {
                    hide();
                    if (searchUser.length > 0) {
                        this.setState({
                            notifyUsers: distinct(this.state.notifyUsers.concat(searchUser.map(({ key, label }: { key: string, label: string }) => {
                                var pos = label.lastIndexOf(' (');
                                if (pos == -1) {
                                    label = `${label}()`;
                                    pos = label.lastIndexOf(' (');
                                }
                                return {
                                    role: NotificationRoles.IndividualUser,
                                    targetType: NotificationTargetTypes.IndividualUser,
                                    targetId: key,
                                    rowKey: key,
                                    name: label.substring(0, pos),
                                    email: label.substr(pos + 1).replace(/[()]/g, '')
                                }
                            })), (u: any) => u.rowKey)
                        }, this.checkNotifyListCallBack.bind(this));
                    }
                }}
                onCancel={() => hide()}
            >
                {this.accessibleRegions.length > 1 && <div className="region-section">
                    <p>
                        <label htmlFor="Region" className="ant-form-item-required">
                            {fmtMsg({
                                id: GSAdminLocale.NotificationCreateRegion
                            })}
                        </label>
                    </p>
                    <Select value={this.state.selectedRegion} showSearch={true} onChange={this.onRegionChange}
                        filterOption={
                            (input, option) => {
                                return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                        }>

                        {this.accessibleRegions.map(d => (
                            <Select.Option key={d.id} value={d.id}>
                                {d.englishName}
                            </Select.Option>
                        ))}
                    </Select>
                </div>}

                <Select
                    mode='multiple'
                    labelInValue
                    style={{ width: '100%' }}
                    dropdownClassName={`drop-down ${userFetching ? "loading" : ""}`}
                    value={searchUser}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    notFoundContent={userFetching ? <Spin size="small" /> : null}
                    onSearch={(d) => this.userSelectSearch(d)}
                    onChange={this.onUserSelectChange.bind(this)}
                >
                    {probablyUsers.map(d => <Select.Option key={d.id} title={fmtEmail(d)}>{fmtEmail(d)}</Select.Option>)}
                </Select>
            </Modal>
        )
    }
    setNotifiedFilterUsers(filterKey?) {
        this.setState({
            notifiedFilterUsers: filterKey ? this.state.notifiedUsers.filter(u =>
                (u.name && u.name.toLowerCase().includes(filterKey.toLowerCase()))
                || (u.email && u.email.toLowerCase().includes(filterKey.toLowerCase()))) : this.state.notifiedUsers
        })
    }
    renderUserNotifiedModal() {
        const hide = () => this.setState({ modal: { ...this.state.modal, userNotified: { visible: false } }, notifiedFilterUsers: this.state.notifiedUsers });
        return (
            <Modal
                title={fmtMsg(GSAdminLocale.NotificationCreateCheckUsers)}
                visible={this.state.modal.userNotified.visible}
                className="notified-users-modal"
                maskClosable={false}
                destroyOnClose
                onOk={() => hide()}
                onCancel={() => hide()}
                footer={null}
            >
                <Input onChange={(e) => {
                    this.usersFilter((e.target.value as string || '').trim())
                }}></Input>
                <Table
                    dataSource={this.state.notifiedFilterUsers}
                    columns={[
                        {
                            title: fmtMsg(GSAdminLocale.NotificationTextName),
                            dataIndex: 'name',
                            width: '40%',
                        },
                        {
                            title: fmtMsg(GSAdminLocale.NotificationTextEmail),
                            dataIndex: 'email',
                            width: '60%',
                        }
                    ]}
                    scroll={{ y: 220 }}
                    rowKey="id"
                    pagination={false}
                />
            </Modal>
        )
    }
    removeNotifise(notifise, key, index, target?: NotificationToTargets) {
        var next = [...notifise];
        next.splice(index, 1);
        var state = { [key]: next } as any;
        if (target == NotificationToTargets.Roles) {
            if (next.length == 0) {
                state.notifiedUserCount = 0;
            } else {
                this.getTargetCount(next);
            }
        }
        this.setState(state);
    }
    getRoleTargetColumns() {
        return [{
            title: fmtMsg(GSAdminLocale.NotificationTextRoleName),
            dataIndex: 'roleName',
        }, {
            title: fmtMsg(GSAdminLocale.NotificationTextTargetTypeName),
            dataIndex: 'targetTypeName',
        }, {
            title: fmtMsg(GSAdminLocale.NotificationTextTargetName),
            dataIndex: 'targetName',
        }, {
            render: (_, record, index) => <Icon type="delete" onClick={() => Modal.confirm({
                title: fmtMsg({ id: GSAdminLocale.NotificationCreateSureDelete }),
                onOk: () => {
                    if (this.state.disableBtnSubmit) this.setState({ disableBtnSubmit: false })
                    this.removeNotifise(this.state.formatRoleTargets, 'formatRoleTargets', index, NotificationToTargets.Roles)
                }
            })} />
        }];
    }
    getUserColumns() {
        return [{
            title: fmtMsg(GSAdminLocale.NotificationTextName),
            dataIndex: 'name',
        }, {
            title: fmtMsg(GSAdminLocale.NotificationTextEmail),
            dataIndex: 'email',
        }, {
            render: (_, record, index) => <Icon type="delete" onClick={() => Modal.confirm({
                title: fmtMsg({ id: GSAdminLocale.UsersEditDelete }),
                onOk: () => {
                    if (this.state.disableBtnSubmit) this.setState({ disableBtnSubmit: false })
                    this.removeNotifise(this.state.notifyUsers, 'notifyUsers', index)
                }
            })} />
        }];
    }
    onNotificationChannelChange(checkedList) {
        this.notificationChannel = checkedList;
        if (this.state.disableBtnSubmit) this.setState({ disableBtnSubmit: false })
    }
    visibleModal(key) {
        const modal = { ...this.state.modal };
        modal[key].visible = true;
        this.setState({ modal });
    }

    renderLink(selector, field, visible: string, length) {
        const { form } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        return <LazyPortal selector={selector}>
            <Popover
                {...alignPop()}
                visible={this.state[visible]}
                onVisibleChange={(linkVisible) => {
                    this.setState({ [visible]: linkVisible } as any);
                    if (!linkVisible) {
                        form.resetFields(['text', 'url']);
                    }
                }}
                overlayClassName="home-notification"
                content={<div>
                    <GLForm className="link-form" form={form} onSubmit={(e) => {
                        e.preventDefault();
                        this.props.form.validateFields(['text', 'url'], (err, values) => {
                            if (!err) {
                                const origin = form.getFieldValue(field);
                                form.setFieldsValue({ [field]: `${origin ? `${origin} ` : ''}#[${values.text}](${values.url}) ` });
                                form.resetFields(['text', 'url']);
                                this.setState({ [visible]: false } as any)
                            }
                        });
                    }}>
                        {renderFormItem(form, GSAdminLocale.NotificationCreateLinkText, 'text', <Input />, null, true, lengthValider(GSAdminLocale.NotificationCreateTitle, length))}
                        {renderFormItem(form, GSAdminLocale.NotificationCreateLinkUrl, 'url', <Input />, null, true, urlValider(fmtMsg(GSAdminLocale.NotificationCreateLinkUrlLegal)).concat(lengthValider(GSAdminLocale.NotificationCreateTitle, length)))}
                        <div>
                            <Button type='primary' htmlType='submit'>{fmtMsg({ id: GLLocale.Ok })}</Button>
                        </div>
                    </GLForm>
                </div>}
                title={fmtMsg(GSAdminLocale.NotificationCreateLinkTitle)}
                trigger="click"
            >
                <Icon type="link" title={fmtMsg(GSAdminLocale.NotificationCreateLink)} />
            </Popover>
        </LazyPortal>
    }

    validateDescription = (): boolean => {
        if (this.state.useAdvancedEditor) return true;

        const stripTags = stripHtmlTags(this.richTextNotificationDescription);
        if (!stripTags || !stripTags.trim().length) {
            this.richTextNotificationDescriptionError = fmtMsg(GSAdminLocale.NotificationRequireDescription);
            return false;
        }
        if (stripTags.length > 4000) {
            this.richTextNotificationDescriptionError = fmtMsg(GSAdminLocale.NotificationMaxLengthDescription);
            return false;
        }
        return true;
    }

    onRichTextEditorChange = (value) => {
        this.richTextNotificationDescription = value;
    }

    showSwitchTargetToast = () => {
        const messageId = this.state.notifyChanged == NotificationToTargets.Roles
            ? GSAdminLocale.NotificationSwitchTypeErrorRole
            : GSAdminLocale.NotificationSwitchTypeErrorUser;
        MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: messageId }));
    }

    render() {
        const { form } = this.props;
        const { model, useAdvancedEditor } = this.state;
        const renderFormItem = FormHelper.renderFormItem;
        const dateValider = (msg) => rangeDateValider(form, 'startDate', 'endDate', msg);
        const notifyRole = this.state.notifyChanged == NotificationToTargets.Roles;
        const notifyCount = notifyRole ? this.state.notifiedUserCount : this.state.notifyUsers.length;
        const isEn = GLGlobal.store.getState().intl.langLoaded === 'en';
        const isRadioButtonEnabled = (type: NotificationToTargets) => {
            return (!notifyCount || this.state.notifyChanged == type);
        }
        return (
            <div className="notification-create">
                <GLForm className="notification-form" form={form} onSubmit={this.onSubmit.bind(this)}>
                    {renderFormItem(form, GSAdminLocale.NotificationCreateTitle, 'title', <Input />, model.title, true, lengthValider(GSAdminLocale.NotificationCreateTitle, 200))}

                    <div className={this.richTextNotificationDescriptionError && this.richTextNotificationDescriptionError.length ? "has-error mb-15" : ""}>
                        <div className="notify-list">
                            <span>
                                <label className="ant-form-item-required ant-form-item-no-colon ant-col ant-form-item-label">
                                    {fmtMsg({ id: GSAdminLocale.NotificationCreateDescription })}
                                </label>
                            </span>
                            <span>
                                {useAdvancedEditor && <a style={{ marginRight: '1rem' }} href="javascript:void(0)" onClick={() => this.removeAdvancedEditor()}>{fmtMsg({ id: GSAdminLocale.SchoolAdminRemove })}</a>}
                                <a href="javascript:void(0)" onClick={() => { this.visibleModal('advancedEditor'); this.setState({ disableBtnAdvanceEditor: true }) }}>{fmtMsg({ id: GSAdminLocale.NotificationCreateAdvancedEditor })}</a>
                            </span>
                        </div>
                        {
                            useAdvancedEditor ?
                                <div style={{ width: '100%', marginBottom: 25 }} ref={(ref) => {
                                    if (!ref) return;
                                    if (this.shadowHost.current === null) {
                                        this.shadowHost = ref;

                                        if (!ref.shadowRoot)
                                            this.shadowHost.attachShadow({ mode: 'open' });
                                    }
                                    this.shadowHost.shadowRoot.innerHTML = this.advancedNotificationDescription;
                                }} className="notification-content">
                                </div>
                                : <>
                                    <GLRichTextEditor onValueChanged={this.onRichTextEditorChange}
                                        editorHtml={this.richTextNotificationDescription}>
                                    </GLRichTextEditor>
                                    <div className="ant-form-explain">{this.richTextNotificationDescriptionError}</div>
                                </>
                        }

                    </div>

                    <div className="range-date">
                        {renderFormItem(form, GSAdminLocale.NotificationCreateStartDate, 'startDate', <DatePicker {...alignPop({ type: 'DatePicker' })} />, DateHelper.toLocalMoment(model.startDate), true, dateValider(fmtMsg({ id: GSAdminLocale.NotificationCreateEarlierDate })))}
                        {renderFormItem(form, GSAdminLocale.NotificationCreateEndDate, 'endDate', <DatePicker {...alignPop({ type: 'DatePicker' })} />, DateHelper.toLocalMoment(model.endDate), true, dateValider(fmtMsg({ id: GSAdminLocale.NotificationCreateLaterDate })))}
                    </div>
                    {renderFormItem(form, GSAdminLocale.NotificationCreateNotificationType, 'notificationType',
                        <Radio.Group onChange={e => {
                            if (this.state.disableBtnSubmit) this.setState({ disableBtnSubmit: false })
                            this.setState({ notifyChanged: Number(e.target.value), notifyUsers: [], notifiedUserCount: 0, formatRoleTargets: [] })
                        }}>
                            <PreventClickingMask onMaskClick={this.showSwitchTargetToast}
                                allowClicking={isRadioButtonEnabled(NotificationToTargets.Roles)}>
                                <Radio value={NotificationToTargets.Roles}>{fmtMsg({ id: GSAdminLocale.NotificationCreateRoles })}</Radio>
                            </PreventClickingMask>
                            <PreventClickingMask onMaskClick={this.showSwitchTargetToast}
                                allowClicking={isRadioButtonEnabled(NotificationToTargets.Users)}>
                                <Radio value={NotificationToTargets.Users}>{fmtMsg({ id: GSAdminLocale.NotificationCreateUsers })}</Radio>
                            </PreventClickingMask>
                        </Radio.Group>, model.notificationType, true)}
                    <div>
                        <div className="notify-list has-error">
                            <span><label htmlFor="Notify" className="ant-form-item-required" >{fmtMsg({ id: GSAdminLocale.NotificationCreateNotify })}</label>{this.state.checkNotifyList ? <span className="ant-form-explain">{fmtMsg({ id: GSAdminLocale.NotificationCreateNotifyValidate })}</span> : null}</span>
                            {notifyRole ? <a href="javascript:void(0)" onClick={() => this.visibleModal('roleTarget')}><Icon type="plus-circle-o" />{fmtMsg({ id: GSAdminLocale.NotificationCreateNotifyAddRole })}</a> : <a href="javascript:void(0)" onClick={() => this.visibleModal('user')}><Icon type="plus-circle-o" />{fmtMsg({ id: GSAdminLocale.NotificationCreateNotifyAddUser })}</a>}
                        </div>
                        <div className="notify-count"><div><Icon type="flag" theme="filled" /></div><div>{notifyCount == 0 || !notifyRole ? notifyCount : <a href="javascript:void(0)" onClick={() => this.visibleModal('userNotified')}>{notifyCount}</a>}{isEn ? (` user${notifyCount <= 1 ? '' : 's'} `) : ' '}{fmtMsg({ id: GSAdminLocale.NotificationReviewNotified })}</div></div>
                        {notifyRole ? <Table rowKey={'rowKey'} dataSource={this.state.formatRoleTargets} columns={this.getRoleTargetColumns()} pagination={false} /> : null}
                        {this.state.notifyChanged == NotificationToTargets.Users ? <Table rowKey={'rowKey'} dataSource={this.state.notifyUsers} columns={this.getUserColumns()} pagination={false} /> : null}
                    </div>
                    {renderFormItem({ ...form, formItemProps: { label: '' }, decoratorOptions: { valuePropName: 'checked' } }, GSAdminLocale.NotificationCreateSticky, 'sticky', <Checkbox >{fmtMsg({ id: GSAdminLocale.NotificationCreateSticky })}</Checkbox>, model.sticky, false)}
                    {renderFormItem(form, GSAdminLocale.NotificationCreateChannel, 'notificationChannel',
                        <Checkbox.Group onChange={this.onNotificationChannelChange.bind(this)}>
                            <Checkbox value={NotificationChannel.BulletinBoard} >{fmtMsg({ id: GSAdminLocale.NotificationCreateChannelBulletinBoard })}</Checkbox>
                            <Checkbox value={NotificationChannel.Email} >{fmtMsg({ id: GSAdminLocale.NotificationCreateChannelEmail })}</Checkbox>
                        </Checkbox.Group>, this.notificationChannel, false, [
                        {
                            required: true,
                            message: `${fmtMsg(
                                { id: GLLocale.FormPrefixPleaseSelect },
                                { name: fmtMsg({ id: GSAdminLocale.NotificationCreateChannel }).toLowerCase() }
                            )}`
                        }
                    ])}
                    <div>
                        <Button type='primary' disabled={this.state.disableBtnSubmit} htmlType='submit'>{fmtMsg({ id: GSAdminLocale.NotificationCreateSubmit })}</Button>
                        <Button onClick={() => this.props.history.push({ pathname: PathConfig.NotificationCreated })}>{fmtMsg({ id: GSAdminLocale.NotificationCreateCancel })}</Button>
                    </div>
                </GLForm>
                {this.renderSelectRoleTargetModal()}
                {this.renderUserNotifiedModal()}
                {this.renderSelectUserModal()}
                {this.renderAdvancedEditorModal()}
                {this.renderLink('.notification-form>div:first-child', 'title', 'titlelinkVisible', 45)}
            </div >
        )
    }
    removeAdvancedEditor(): void {
        Modal.confirm({
            title: fmtMsg({ id: GSAdminLocale.NotificationCreateDelete }),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                this.advancedNotificationDescription = "";
                this.advancedNotificationDesign = null;
                // this.richTextNotificationDescription = "";
                this.setState({
                    useAdvancedEditor: false
                })

                if (this.emailEditorRef && this.emailEditorRef.current && this.emailEditorRef.current.editor) {
                    this.emailEditorRef.current.editor.loadBlank();
                }

                this.shadowHost.current = null;
            }
        });
    }

    renderAdvancedEditorModal() {
        const hideModal = () => this.setState({ modal: { ...this.state.modal, advancedEditor: { visible: false } } });
        const exportHtml = () => {
            this.emailEditorRef.current.editor.exportHtml(data => {
                let { design, chunks: { body, css } } = data;
                body = DOMPurify.sanitize(body, { ADD_ATTR: ['target'] })
                this.advancedNotificationDescription = `<style>${css}</style>${body}`;
                this.advancedNotificationDesign = design;
                // this.richTextNotificationDescription = "";
                this.setState({
                    useAdvancedEditor: true
                });
                hideModal();
            }, {
                cleanup: true,
                minify: true
            });
        };

        const options = {
            tools: {
                html: {
                    enabled: false
                }
            },
            features: {
                ai: false
            }
        }

        const onLoad = () => {
            if (this.props.isEdit && this.advancedNotificationDesign !== null) {
                this.emailEditorRef.current.editor.loadDesign(this.advancedNotificationDesign);
            }
        };

        const onReady = () => {
            if (this.state.disableBtnAdvanceEditor) this.setState({ disableBtnAdvanceEditor: false })

            if (this.emailEditorRef && this.emailEditorRef.current && this.emailEditorRef.current.editor) {
                this.emailEditorRef.current.editor.hidePreview();
            }
        }

        return (
            <Modal
                className="advanced-editor-modal"
                title={fmtMsg({ id: GSAdminLocale.NotificationCreateAdvancedEditor })}
                visible={this.state.modal.advancedEditor.visible}
                maskClosable={false}
                closable={false}
                onCancel={() => hideModal()}
                onOk={() => exportHtml()}
                footer={[
                    <Button key="back" onClick={() => hideModal()}>
                        {fmtMsg({ id: GSAdminLocale.ButtonCancel })}
                    </Button>,
                    <Button key="submit" type="primary" disabled={this.state.disableBtnAdvanceEditor} onClick={() => exportHtml()}>
                        {fmtMsg({ id: GSAdminLocale.ButtonOk })}
                    </Button>,
                ]}
            >
                <EmailEditor ref={this.emailEditorRef} options={options} onLoad={onLoad} onReady={onReady} />
            </Modal>
        )
    }
}
enum NotificationToTargets {
    Roles = 0,
    Users = 1
}
const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
const createdNotificationRoles = [
    RoleName.systemAdmin,
    RoleName.globalHead,
    RoleName.regionAdmin,
    RoleName.accountManager,
    RoleName.trainingAdmin,
    RoleName.trainer,
    RoleName.trainingManager,
    RoleName.schoolAdmin,
    RoleName.campusAdmin,
];
const rolesHasRegionTargetType = [
    RoleName.systemAdmin,
    RoleName.globalHead,
    RoleName.trainingAdmin,
    RoleName.trainingManager,
    RoleName.trainer,
    RoleName.regionAdmin
];
const rolesHasSchoolTargetType = [
    RoleName.systemAdmin,
    RoleName.globalHead,
    RoleName.trainingAdmin,
    RoleName.trainingManager,
    RoleName.regionAdmin,
    RoleName.trainer,
    RoleName.accountManager,
    RoleName.schoolAdmin
];

const userHasRole = (role) => (GLGlobal.loginInfo().profile || { roles: [] }).roles.some(r => r == role);
const hasOneRole = (role) => (GLGlobal.loginInfo().profile || { roles: [] }).roles.filter(r => createdNotificationRoles.some(cr => cr == r)).length == 1 && userHasRole(role);
const isUserHasRegionTargeType = () => (GLGlobal.loginInfo().profile || { roles: [] }).roles.filter(r => rolesHasRegionTargetType.some(rtt => rtt == r)).length > 0;
const isUserHasSchoolTargeType = () => (GLGlobal.loginInfo().profile || { roles: [] }).roles.filter(r => rolesHasSchoolTargetType.some(rtt => rtt == r)).length > 0;
export function rangeDateValider({ getFieldsValue, getFieldError, resetFields, setFieldsValue }, start, end, msg) {
    return [{
        validator: (rule, value, callback) => {
            const dates = getFieldsValue([start, end]);
            if (dates[start] > dates[end]) {
                callback(msg);
            } else {
                const reset = (field) => {
                    const errors = getFieldError(field);
                    if (errors && errors.length > 0) {
                        resetFields([field]);
                        setFieldsValue({ [field]: dates[field] })
                    }
                }
                reset(start)
                reset(end)
            }
            callback();
        }
    }];
}
